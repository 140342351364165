import React from "react";
import './Aboutus.css';

const teamMembers = [
  {
    name: 'Kannan KN',
    designation: 'Managing Trustee',
    paragraph: 'An Executive Coach and Business Strategist, Kannan has held CEO and CXO positions in both VC funded startups as well as large corporations and business groups like Adidas, the Tata Group, the Jardine Group and Practo',
    imageUrl: 'KNK Solo LB.jpg',
  },
  {
    name: 'Dattatri Salagame',
    designation: 'Trustee',
    paragraph: 'An Advisor and Mentor for Technology startups, Dattatri is the co-founder of Happiest Minds Technologies and has held senior positions in various IT companies like MindTree',
    imageUrl: 'dattasirpic.jpg',
  },
  {
    name: 'Raghuram Mokshagundam',
    designation: 'Head-Outreach and Placements',
    paragraph: 'A Senior HR and Administration professional who has held senior positions in companies like Dunzo, Practo, Volvo, Mico Bosch and Titan, across continents, Raghuram is also a proud 3rd generation member in the family tree of Bharat Ratna M Visvesvaraya. For Swapnodaya, Raghuram heads all of the Trusts Placements and Outreach efforts',
    imageUrl: 'raghusir.jpeg',
  },
  {
    name: 'Krupasagar',
    designation: 'Manager - Upskilling and IT',
    paragraph: 'An M. Tech in Computer Science, Krupasagar, an ex- assistant professor, is intensely passionate about helping the needy quite unusual in a man still in his twenties. Krupasagar handles all aspects of formulating and administering our upskilling programs and also is handson while creating our website and portal',
    imageUrl: 'krupasagar.jpg',
  },
];
  
  
  const Volunteer = [
  {
    name: 'Vanishri Deshpande',
    designation: 'Volunteer',
    paragraph: 'Vani is the Co-Founder of Spottabl, a ‘Recruiting Marketplace’ platform. A senior HR professional, with specialisation in Talent Acquisition across levels, Vani has over 20 years of experience working in Tech startups as well as large corporates like Flipkart, AOL India, Practo, EFI India and Microsoft Research India Labs. For Swapnodaya, Vani will provide guidance and assistance in the placement of the Swapnodaya students, and also mentor them for assessments and interviews',
    imageUrl: 'vaani.jpg',
  },
  {
    name: 'Ramesh N',
    designation: 'Volunteer',
    paragraph: 'Ramesh is a management graduate with 17 years experience driving IT strategy and program execution to enable business growth and drive operational efficiency. Ramesh has over 12 years of program management and product management experience leading multiple cross-functional teams. Currently, Ramesh is leading the Technical Program Management team for Salesforce Industries in India, Ramesh is associated with Swapnodaya as a volunteer helping with alumni connect and driving outreach efforts with select organizations who are keen to partner with Swapnodaya.',
    imageUrl: 'rameshn.jpg',
  },
  {
    name: 'Srikartik Sayana',
    designation: 'Volunteer',
    paragraph: 'A Talent Acquisition leader with extensive experience in scaling companies like Practo and Uber, SriKartik currently leads recruiting for Meesho. For Swapnodaya, Srikartik will provide guidance in making the students relevant and suitable for the current job market, and  in identifying and pursuing the right channels for placements.',
    imageUrl: 'karthik sayana.jpeg',
  },
  {
    name: 'Dipen Wahi',
    designation: 'Volunteer',
    paragraph: 'Dipen Wahi is a seasoned Private Wealth Professional with over 12 years of experience in prestigious firms such as Equirus Wealth, Citibank, and HDFC Bank. His commitment to give back to society has drawn him towards Swapnodaya. He will put in his efforts to raise funds for sustaining and scaling Swapnodaya’s impact of permanently uplifting and empowering the underprivileged.',
    imageUrl: 'dipen.jpeg',
  },
  {
    name: 'Mrs Srikala Bhashyam',
    designation: 'Volunteer for Resource Mobilisation',
    paragraph: "Srikala Bhashyam is the co-founder of Vithadwaitha Financial Services. She has been in the business of talking and managing money for nearly three decades in different avatars as a journalist with the Times of India, as an investment analyst with investment  magazines and finally as money manager for over 100 families across the globe through her current company, Vithadwaitha. Impressed with Swapnodaya's work, Srikala has been extending support to the Trust as a donor in her personal capacity. In addition she will now assist Swapnodaya in its fundraising activities as a volunteer, to support the sustenance and scaling of its operations and impact in the days to come.",
    imageUrl: 'srikala.jpg',
  },

  // {
  //   name: 'Pavithra H M',
  //   designation: 'Volunteer',
  //   paragraph: 'Pavithra is a senior HR professional with over 15 years of work experience in companies like Accenture, JDA, Zyme solutions, Sonata software and Tata Trust. An MBA in HR, she has worked in both the IT as well as non IT sectors. She is currently a Director in ER Solutions, a Consulting startup. An active member of the ‘Art of Living’ and a Vedic Math tutor, Pavithra will leverage her experience in the NGO / Nonprofit space to assist Swapnodaya, both in raising CSR funds as well as in the placement of its students.',
  //   imageUrl: 'pavitra1.jpeg',
  // },


  

];

function Aboutus() {
    return (
        <><><h1 className="Abouttitle"> Meet Our Team</h1>
        <div className="aboutteam-container">
          {teamMembers.map((member, index) => (

            <div className="aboutcard-container" key={index}>
              <div className="aboutcard">
                <img className="aboutcard-image" src={member.imageUrl} alt={member.name} />
                <div className="aboutcard-details">
                  <p className="aboutcard-name">{member.name}</p>
                  <p className="aboutcard-designation">{member.designation}</p>
                  <p className="aboutcard-paragraph">{member.paragraph}</p>
                </div>
              </div>
            </div>
          ))}
        </div></>
        
        <>
          <div className="volunteers-container">
            {Volunteer.map((member, index) => (

              <div className="volunteerscard-container" key={index}>
                <div className="volunteerscard">
                  <img className="volunteerscard-image" src={member.imageUrl} alt={member.name} />
                  <div className="volunteerscard-details">
                    <p className="volunteerscard-name">{member.name}</p>
                    <p className="volunteerscard-designation">{member.designation}</p>
                    <p className="volunteerscard-paragraph">{member.paragraph}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </> 
          </>
    );
}

export default Aboutus;


   
