import React, { useState, useEffect  } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';


function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closemobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth < 768) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton(); // Call showButton initially

    window.addEventListener('resize', showButton);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('resize', showButton);
    };
  }, []);

  
  // window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div className='navbar-container' >
          <Link to = "/" className="navbar-logo">
            SWAPNODAYA
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closemobileMenu}>
                Home
              </Link> 
            </li>
            <li className='nav-item'>
              <Link to='/Training' className='nav-links' onClick={closemobileMenu}>
                Training
              </Link> 
            </li>
            <li className='nav-item'>
              <Link to='/Internship' className='nav-links' onClick={closemobileMenu}>
                Internship
              </Link> 
            </li>
            <li className='nav-item'>
              <Link to='/Students' className='nav-links' onClick={closemobileMenu}>
                Students
              </Link> 
            </li>
            <li className='nav-item'>
              <Link to='/Placements' className='nav-links' onClick={closemobileMenu}>
                Placements
              </Link> 
            </li>
            <li className='nav-item'>
              <Link to='/About' className='nav-links' onClick={closemobileMenu}>
                Our Team
              </Link> 
            </li>
            <li className='nav-item'>
              <Link to='/Contact' className='nav-links' onClick={closemobileMenu}>
                Contact Us
              </Link> 
            </li>

            <li className='nav-item'>
              <Link to='/Login' className='nav-links-mobile' onClick={closemobileMenu}>
                Course Login
              </Link> 
            </li>
           
            <li className='nav-item'>
              <Link to='/Login' className='nav-links-mobile' onClick={closemobileMenu}>
                Login
              </Link> 
            </li>
          </ul>
          {button && (<Button buttonStyle='btn--outline'>Login</Button>)}
          
        </div>
        <Outlet/>
      </nav>
    </>
  )
}

export default Navbar
